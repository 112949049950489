// src/pages/App.jsx
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import wp from './mvrxwp-v1.1.pdf'; // Ensure the PDF file path is correct
import banner from './banner.png';
import './App.css';

const App = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div>
     <div className="header-container flex justify-center items-center m-10">
        <h2 className='text-center m-10'>
          MuvorX WhitePaper
        </h2>
        <a href="https://www.muvor.com" target="_blank" rel="noopener noreferrer">
          <img src={banner} alt="Muvor WhitePaper v1.1" className="banner-image" style={{ maxHeight: '50px', marginRight: '20px' }} />
        </a>
      </div>
      <div className='flex justify-center'>
        <div className='w-full' style={{ height: '750px' }}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer
              fileUrl={wp}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default App;
